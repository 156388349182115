/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../Assets/Images/logo.png';
import { functions } from '../../firebase';
import LoaderComponent from '../../Shared/Loader';
import { getConfirmation } from '../../utils/ui';

const OpenProjectPage = ({ match, history }) => {
    const [projectDetail, setProjectDetail] = useState<any>({});
    const [error, setError] = useState('');
    const [isProjectValid, setIsProjectValid] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const techId = useMemo(() => (match.params ? match.params.techId : null), [match]);
    const projectId = useMemo(() => (match.params ? match.params.projectId : null), [match]);

    const checkIfProjectInviteValid = useCallback(async () => {
        setShowLoader(true);
        try {
            const { data } = await functions.httpsCallable('checkIfProjectInviteValid')({
                projectId,
                techId,
            });
            setProjectDetail(data);
            setIsProjectValid(!!data);
        } catch (errors) {
            setError(errors.message);
        }
        setShowLoader(false);
    }, [projectId, techId]);

    const callDibsOnProject = async () => {
        setShowLoader(true);
        try {
            await functions.httpsCallable('acceptProjectInvite')({
                projectId,
                techId,
            });
            await getConfirmation(
                'Success! You are now part of this project.',
                ' You should have received the details to your phone. Click Login to proceed.',
                {
                    showCancelButton: false,
                    confirmButtonText: 'Go to login',
                    cancelButtonText: '',
                },
            );
            history.push('/login');
        } catch (errors) {
            setError(errors.message);
        }
        setShowLoader(false);
    };

    useEffect(() => {
        if (techId && projectId) {
            checkIfProjectInviteValid();
        }
    }, [techId, projectId, checkIfProjectInviteValid]);

    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500">
            <LoaderComponent showLoader={showLoader} />
            <div className="flex flex-col">
                <div className="mt-20 text-center mx-auto">
                    <img src={logo} alt="Logo" className="text-center mx-auto w-8/12" />
                    <p className="text-lg font-medium text-center text-white mt-4">Technician Scheduling Made Easy</p>
                </div>
                <div className="text-center mx-auto mt-8 bg-white w-full md:w-3/5 rounded shadow py-4 px-2">
                    {isProjectValid === true ? (
                        <>
                            <div className="uppercase text-xl font-medium px-3">
                                {projectDetail.clientName}: {projectDetail.title}
                            </div>
                            <div className="mt-4 text-base text-gray-600 text-center">
                                <p className="my-1">{projectDetail.notes}</p>
                                <p className="my-1">Start Date: {projectDetail.startDate}</p>
                                <p className="my-1">Number of vehicles: {projectDetail.numberOfVehicles}</p>
                            </div>
                            <div className="my-8">
                                <button
                                    type="button"
                                    onClick={() => callDibsOnProject()}
                                    className="text-lg bg-blue-500 text-white px-3 py-2 rounded w-72 capitalize"
                                >
                                    Assign yourself
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="py-4 bg-red-500 w-full text-white text-center mb-3">
                                {error ? (
                                    <span>{error}</span>
                                ) : (
                                    "Oh no, that project doesn't exist or is no longer available!"
                                )}
                            </div>
                            <div className="my-8">
                                <NavLink
                                    to="/login"
                                    className="text-lg bg-green-500 text-white px-3 py-2 rounded hover:bg-blue-800"
                                >
                                    Login
                                </NavLink>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default OpenProjectPage;

OpenProjectPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};
