import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import LoaderComponent from '../../Shared/Loader';
import { AuthContext } from '../../Providers/UserProvider';

const StatusPage = () => {
    const { techId, tech, shopsRef, techsRef, jobs } = useContext(AuthContext);

    const [selectedLocation, setSelectedLocation] = useState<any>({});

    const [showLoader, setShowLoader] = useState(false);

    const [locations, setlocations] = useState<any>([]);

    const [userLat, setUserLat] = useState<number>();

    const [userLng, setUserLng] = useState<number>();

    const [filterTerm, setFilterTerm] = useState<string>();

    const isAvailable = useMemo(() => tech.status, [tech]);

    const selectedLocationId = useMemo(() => tech.location, [tech]);

    useEffect(() => {
        const gettingLocation = () => {
            navigator.geolocation.getCurrentPosition((position) => {
                if (techId && position.coords.latitude) {
                    setUserLat(position.coords.latitude);
                    setUserLng(position.coords.longitude);
                }
            });
        };
        gettingLocation();
        const getLocation = setInterval(() => {
            gettingLocation();
        }, 60000);
        return () => clearInterval(getLocation);
    }, [techId]);

    useEffect(() => {
        if (userLat && userLng) {
            techsRef.doc(techId).update({
                lat: userLat,
                lng: userLng,
            });
        }
    }, [techId, techsRef, userLat, userLng]);

    useEffect(() => {
        setShowLoader(true);
        let shopAddress;
        if (selectedLocationId) {
            setShowLoader(true);
            shopAddress = shopsRef.doc(selectedLocationId || '').onSnapshot((documentSnapshot) => {
                if (documentSnapshot.data()) {
                    const shop = { ...documentSnapshot.data(), id: documentSnapshot.id };
                    setSelectedLocation(shop);
                }
            });
            setShowLoader(false);
        }
        setShowLoader(false);
        return shopAddress;
    }, [selectedLocationId, shopsRef]);

    useEffect(() => {
        setShowLoader(true);
        return shopsRef.onSnapshot((querySnapshot) => {
            const arrayShops: any[] = [];
            querySnapshot.forEach((documentSnapshot) => {
                let newShops = documentSnapshot.data();
                newShops = { ...newShops, id: documentSnapshot.id };
                arrayShops.push(newShops);
            });
            setShowLoader(false);
            setlocations(arrayShops);
        });
    }, [shopsRef]);

    const activeJobs = jobs.filter((job) => job.status === 'active');

    const activeJobsLength = useMemo(() => activeJobs.length, [activeJobs]);

    const updateStatus = (currentStatus) => {
        if (currentStatus === false) {
            techsRef.doc(techId).update({
                status: 'unavailable',
            });
        } else {
            techsRef.doc(techId).update({
                status: 'available',
            });
        }
    };

    const updateSelection = useCallback(
        (location: any) => {
            if (selectedLocation?.id === location?.id) {
                setSelectedLocation(null);
                techsRef.doc(techId).update({
                    location: 'none',
                });
                return;
            }
            setSelectedLocation(location);
            techsRef.doc(techId).update({
                location: location?.id,
            });
        },
        [selectedLocation?.id, techId, techsRef],
    );

    const filteredShops = locations.filter((shop) =>
        JSON.stringify(shop.name)
            .toLowerCase()
            .includes(filterTerm || ''),
    );

    return (
        <>
            <LoaderComponent showLoader={showLoader} />
            <div className="flex flex-col h-screen w-full">
                <div className="bg-gray-600 py-8 text-center pl-2.5 pr-2.5 fixed w-screen z-40">
                    {isAvailable === 'available' ? (
                        <p className="text-white text-xl">
                            {activeJobsLength > 0 ? (
                                <span>You are currently on an active job.</span>
                            ) : (
                                <span>
                                    {' '}
                                    You are currently marked as available for work at{' '}
                                    {selectedLocationId !== 'none' ? selectedLocation?.name : ' no specific location'}.
                                </span>
                            )}
                        </p>
                    ) : (
                        <p className="text-white text-xl">Are you available for work?</p>
                    )}
                    <div className="mt-6 grid grid-cols-2">
                        <div>
                            <button
                                type="button"
                                onClick={() => updateStatus(false)}
                                className={`text-white font-bold py-3 px-4 rounded focus:outline-none shadow-md${
                                    isAvailable !== 'available'
                                        ? ' bg-red-400 hover:bg-red-700'
                                        : ' bg-gray-400 hover:bg-gray-700'
                                }`}
                            >
                                Unavailable
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                onClick={() => updateStatus(true)}
                                className={`text-white font-bold py-3 px-4 rounded focus:outline-none shadow-md${
                                    isAvailable === 'available'
                                        ? ' bg-green-400 hover:bg-green-700'
                                        : ' bg-gray-400 hover:bg-gray-700'
                                }`}
                            >
                                Available
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <div className="mb-16 mt-56 container mx-auto text-left">
                        <div className="flex flex-col items-center">
                            <div className="shadow flex w-8/12 mb-5">
                                <input
                                    className="w-full p-2 border border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    value={filterTerm}
                                    onChange={(value) => setFilterTerm(value.target.value.toLowerCase())}
                                    type="text"
                                    placeholder="Search..."
                                />
                                <div className="w-auto flex justify-end items-center p-2 text-purple-600">
                                    <FontAwesomeIcon className="text-2xl" icon={faSearchLocation} />
                                </div>
                            </div>
                            {filteredShops.map((location) => (
                                <div
                                    key={`location-${location.id}`}
                                    className="box-border shadow-md flex flex-col bg-blue-900 text-white mb-4 z-30 w-10/12"
                                >
                                    <MapContainer
                                        center={[location.lat ?? 38.940041, location.lng ?? -104.627909]}
                                        zoom={13}
                                        scrollWheelZoom={false}
                                        dragging={false}
                                        attributionControl={false}
                                        className="max-w-full h-32 sm:h-56"
                                    >
                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    </MapContainer>
                                    <div className="p-4">
                                        <p className="text-base">{location.name}</p>
                                        <p className="text-xs">
                                            {location.address?.street}, {location.address?.city},{' '}
                                            {location.address?.state}
                                        </p>
                                        <div className="mt-4 text-center">
                                            <button
                                                type="button"
                                                onClick={() => updateSelection(location)}
                                                className={`text-white font-bold py-1 px-3 rounded focus:outline-none shadow-md ${
                                                    selectedLocation?.id === location.id
                                                        ? ' bg-green-400 hover:bg-green-700'
                                                        : ' bg-gray-400 hover:bg-gray-700'
                                                }`}
                                            >
                                                {selectedLocation?.id === location.id
                                                    ? selectedLocation?.name
                                                    : 'Select Location'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatusPage;
