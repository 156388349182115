import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export const getIsValidPhone = (numToValidate: string) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    let parsedPhone;
    try {
        parsedPhone = phoneUtil.parse(numToValidate, 'US');
    } catch (e) {
        return false;
    }
    return phoneUtil.isValidNumber(parsedPhone);
};

export const getFormattedPhone = (phone: string): string => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.format(phoneUtil.parse(phone, 'US'), PhoneNumberFormat.E164);
};

export const getIsPhoneChanged = (newPhone, oldPhone) => {
    if (!getIsValidPhone(newPhone)) {
        return true;
    }
    return getFormattedPhone(newPhone) !== oldPhone;
};
