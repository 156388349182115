import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { functions } from '../../firebase';
import { AuthContext } from '../../Providers/UserProvider';
import WelcomePage from '../WelcomePage';

function useQuery(): Record<string, any> {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const InviteSplash = () => {
    // If we're logged in, no worries; go to the respective job or project.
    const { user } = useContext(AuthContext);
    const [active, setActive] = useState<boolean | null>(null);
    const query = useQuery();

    useEffect(() => {
        if ([true, 'true'].includes(query.get('active')) || user) {
            setActive(true);
        } else {
            // otherwise manually check active via the endpoint, setting loader accordingly
            functions
                .httpsCallable('checkIsActive')({ techId: query.get('techId') })
                .then(({ data: isActive }) => {
                    setActive(isActive ?? false);
                })
                .catch(() => {});
        }
    }, [query, user]);

    const redirectPath = useMemo(() => {
        // basically if they're not active then we send them to an instructional.
        // if they are active we send them to the appropriate page or login.

        // if there IS a tech ID, the nthis user is logged in
        if (query.get('redirectPath')) {
            return query.get('redirectPath');
        }

        if (query.get('projectId')) {
            // return the project
            return `/app/projects/${query.get('projectId')}`;
        }
        if (query.get('jobId')) {
            return '/app/orders'; // there's no order detail page... hmmm
        }

        return '/app/status';
    }, [query]);

    // if we're not logged in, need to determine if we're active. Will use a fetch for this

    if (active === null) {
        // show loader component
        return null;
    }

    if (active === false) {
        // Show compponent telling user welcome blah blah
        return <WelcomePage redirectPath={redirectPath} />;
    }

    if (redirectPath) {
        return <Redirect to={redirectPath} />;
    }

    return (
        <div>
            <p> Invite Splash </p>
        </div>
    );
};

export default InviteSplash;
