import React from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';

interface IWelcomePageProps {
    redirectPath?: string;
}

const WelcomePage: React.FC<IWelcomePageProps> = ({ redirectPath }) => {
    return (
        <div className="bg-cover min-h-screen bg-gradient-to-b from-black to-blue-500">
            <div className="flex flex-col">
                <div className="mt-32 text-center text-white max-w-1/2 mx-auto">
                    <h3 className="text-3xl font-bold">You&apos;re almost there!</h3>
                    <p className="text-left mt-4">
                        You&apos;ve been invited to the SolutionWorks Tech Scheduler system. To accept jobs on the
                        application you just need to set a password.{' '}
                        <a
                            className="font-bold underline"
                            href={`${process.env.REACT_APP_OTP_URL}/sms${qs.stringify(
                                { redirectPath },
                                { addQueryPrefix: true },
                            )}`}
                        >
                            Click this link to
                        </a>{' '}
                        to start that process.
                    </p>
                </div>
            </div>
        </div>
    );
};

WelcomePage.defaultProps = {
    redirectPath: undefined,
};

WelcomePage.propTypes = {
    redirectPath: PropTypes.string,
};

export default WelcomePage;
