import React, { useState, createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';

import { auth, db } from '../firebase';

const defaultFirebaseContext = {
    user: null,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const [error, setError] = useState(null);

    const [jobs, setJobs] = useState([]);

    const [tech, setTech] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);

    const [userInitialized, setUserInitialized] = useState(false);

    const collectionPrefix = useMemo(() => (user ? `/regions/${user.claims.regionId}` : null), [user]);

    const techsRef = useMemo(() => {
        if (user) {
            return db.collection(`/regions/${user.claims.regionId}/techs`);
        }
        return null;
    }, [user]);

    const shopsRef = useMemo(() => (collectionPrefix ? db.collection(`${collectionPrefix}/shops`) : null), [
        collectionPrefix,
    ]);

    useEffect(() => {
        return auth.onAuthStateChanged(async (u) => {
            if (u) {
                try {
                    const idTokenResult = await u.getIdTokenResult();
                    setUser({
                        ...u,
                        claims: idTokenResult.claims,
                    });
                } catch (err) {
                    setUser(null);
                }
            } else {
                setUser(null);
            }
            setUserInitialized(true);
        });
    }, []);

    useEffect(() => {
        if (userInitialized) {
            if (user?.claims.otp) {
                let ticks = 5;
                const interval = setInterval(() => {
                    setError(`
                  You need to change your password. You are being redirected in ${ticks} second${ticks === 1 ? '' : 's'}
                `);
                    ticks -= 1;
                }, 1000);
                setTimeout(() => {
                    clearInterval(interval);
                    window.location.href = `${process.env.REACT_APP_OTP_URL}/otp`;
                }, 5200);
                auth.signOut();
                return () => {};
            }
            if (user?.claims?.regionId) {
                return db
                    .collection(`/techs`)
                    .doc(user.uid)
                    .onSnapshot((techDoc) => {
                        if (techDoc.exists) {
                            setTech({
                                ...techDoc.data(),
                                id: techDoc.id,
                            });
                            setIsInitialized(true);
                        } else {
                            auth.signOut();
                        }
                    });
            }

            setTech(null);
            setIsInitialized(true);

            if (user) {
                auth.signOut();
            }
        }
        return () => {};
    }, [user, userInitialized]);

    const techId = useMemo(() => tech?.id, [tech]);

    useEffect(() => {
        if (!techId) {
            setJobs([]);
            return () => {};
        }
        return db.collection(`/techs/${techId}/allJobs`).onSnapshot(({ docs }) => {
            setJobs(
                orderBy(
                    docs.map((doc) => ({ ...doc.data(), id: doc.id })),
                    (job) => job.date,
                    'desc',
                ),
            );
        });
    }, [techId]);

    return (
        <AuthContext.Provider
            value={{
                user,
                tech,
                techId,
                initialized: isInitialized,
                collectionPrefix,
                techsRef,
                shopsRef,
                jobs,
                error,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
