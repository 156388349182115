import React, { useEffect, useState, useContext } from 'react';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';

import { db } from '../../firebase';
import { AuthContext } from '../../Providers/UserProvider';
import { TechProject } from '../ProjectDetailPage';
import ProjectResults from './ProjectResults';

const ProjectsListPage: React.FC = () => {
    const { tech, user }: any = useContext(AuthContext);
    const [currentTechProjects, setCurrentTechProjects] = useState([] as TechProject[]);
    const [pastTechProjects, setPastTechProjects] = useState([] as TechProject[]);
    const [futureTechProjects, setFutureTechProjects] = useState([] as TechProject[]);
    const today = format(new Date(), 'yyyy-MM-dd');

    // useEffect(() => {
    //     return db
    //         .collection(`/regions/${user.claims.regionId}/techProjects`)
    //         .where('techId', '==', tech?.id)
    //         .where('endDate', '<', today)
    //         .onSnapshot((projectSnapshot) => {
    //             setPastTechProjects(
    //                 orderBy(
    //                     projectSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as TechProject)),
    //                     ['startDate'],
    //                     ['desc'],
    //                 ),
    //             );
    //         });
    // }, [user, today, tech]);

    // useEffect(() => {
    //     return db
    //         .collection(`/regions/${user.claims.regionId}/techProjects`)
    //         .where('techId', '==', tech?.id)
    //         .where('startDate', '>', today)
    //         .orderBy('startDate', 'desc')
    //         .onSnapshot((projectSnapshot) => {
    //             setFutureTechProjects(
    //                 projectSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as TechProject)),
    //             );
    //         });
    // }, [user, today, tech]);

    useEffect(() => {
        if (!tech?.id) {
            return () => {};
        }
        return db.collection(`/techs/${tech.id}/projects`).onSnapshot((projectSnapshot) => {
            const allDocs = orderBy(
                projectSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as TechProject)),
                'startDate',
                'desc',
            );

            setPastTechProjects(allDocs.filter((proj) => (proj.endDate ?? proj.project.endDate) < today));
            setFutureTechProjects(allDocs.filter((proj) => (proj.startDate ?? proj.project.startDate) > today));
            setCurrentTechProjects(
                allDocs.filter(
                    (proj) =>
                        (proj.startDate ?? proj.project.startDate) <= today &&
                        (proj.endDate ?? proj.project.endDate) >= today,
                ),
            );
        });
    }, [user, today, tech]);

    return (
        <div className="p-4">
            <div className="container mx-auto sm:px-4">
                <div className="mb-12">
                    <h2 className="text-2xl pl-4">Current</h2>
                    <ProjectResults techProjects={currentTechProjects} />
                </div>
                <div className="mb-12">
                    <h2 className="text-2xl pl-4">Upcoming</h2>
                    <ProjectResults techProjects={futureTechProjects} />
                </div>
                <div className="mb-12">
                    <h2 className="text-2xl pl-4">Past</h2>
                    <ProjectResults techProjects={pastTechProjects} />
                </div>
            </div>
        </div>
    );
};

export default ProjectsListPage;
