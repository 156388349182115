import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IProject } from '../../../Interfaces/IProject';

const ProjectApplicationForm: React.FC<{ project: IProject; onSubmit: (vals: any) => void }> = ({
    project,
    onSubmit,
}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [miscellaneousNotes, setMiscellaneousNotes] = useState('');
    const [zipcodes, setZipcodes] = useState('');

    const [doesPDR, setDoesPDR] = useState(false);
    const [doesDetail, setDoesDetail] = useState(false);
    const [doesRAndI, setDoesRAndI] = useState(false);
    const [doesOverspray, setDoesOverspray] = useState(false);
    const [doesMechanical, setDoesMechanical] = useState(false);
    const [doesPaint, setDoesPaint] = useState(false);
    const [doesDiagnostics, setDoesDiagnostics] = useState(false);

    const submit = useCallback(
        (e) => {
            e.preventDefault();
            onSubmit({
                firstName,
                lastName,
                email,
                miscellaneousNotes,
                zipcodes,
                doesPDR,
                doesDetail,
                doesRAndI,
                doesOverspray,
                doesMechanical,
                doesPaint,
                doesDiagnostics,
            });
        },
        [
            firstName,
            lastName,
            email,
            miscellaneousNotes,
            zipcodes,
            doesPDR,
            doesDetail,
            onSubmit,
            doesRAndI,
            doesOverspray,
            doesMechanical,
            doesPaint,
            doesDiagnostics,
        ],
    );

    return (
        <div className="max-w-5xl py-4 mx-auto sm:px-6 lg:px-8 mb-16">
            <h1 className="text-2xl mb-4">SolutionWorks Project Application</h1>

            <div className="py-4 rounded bg-gray-300 p-2">
                <h2 className="text-xl mb-4">Project Details</h2>

                <div className="uppercase font-medium">
                    {project.clientName ?? ''}: {project.title}
                </div>
                <div className="mt-4 text-base text-gray-600">
                    <p className="my-1">{project.notes}</p>
                    <p className="my-1">Start Date: {project.startDate}</p>
                    <p className="my-1">Number of vehicles: {project.numberOfVehicles}</p>
                    <p>{project.address.street}</p>
                    <p>
                        {project.address.city}, {project.address.state} {project.address.zip}
                    </p>
                </div>
            </div>

            <div className="py-4">
                <p> You&apos;ve been identified as potentially a good fit for a Solutionworks Enterprise job.</p>
                <p>
                    If you&apos;re interested, please answer a few questions that we&apos;ll need to get you enrolled.
                    You can expect to hear back from us soon.
                </p>
            </div>

            <form className="space-y-8 divide-y divide-gray-200" onSubmit={submit}>
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="first-name"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                First name
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                    type="text"
                                    name="first-name"
                                    id="first-name"
                                    required
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    autoComplete="given-name"
                                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                                htmlFor="last-name"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                                Last name
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                    type="text"
                                    name="last-name"
                                    required
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    id="last-name"
                                    autoComplete="family-name"
                                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Email address
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="email"
                                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                />
                            </div>
                        </div>

                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                    htmlFor="miscNotes"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    Miscellaneous Notes
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea
                                        id="miscNotes"
                                        name="miscNotes"
                                        value={miscellaneousNotes}
                                        onChange={(e) => setMiscellaneousNotes(e.target.value)}
                                        rows={3}
                                        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                                        defaultValue=""
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label
                                    htmlFor="postal-code"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    ZIP code(s)
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        type="text"
                                        name="postal-code"
                                        id="postal-code"
                                        value={zipcodes}
                                        onChange={(e) => setZipcodes(e.target.value)}
                                        autoComplete="postal-code"
                                        className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <p className="text-gray-500 text-sm">
                                        Enter any zip code where you&apos;re open to working. If multiple, separate by
                                        commas.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Skills</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Please select any of the skills that you *actively utilize*. You can select multiple.
                            </p>
                        </div>
                        <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
                            <div className="pt-6 sm:pt-5">
                                <div role="group" aria-labelledby="select-rules">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                                        <div>
                                            <div
                                                className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                                id="select-rules"
                                            >
                                                Select all that apply
                                            </div>
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:col-span-2">
                                            <div className="max-w-lg space-y-4">
                                                <div className="relative flex items-start">
                                                    <div className="flex items-center h-5">
                                                        <input
                                                            id="PDR"
                                                            name="PDR"
                                                            checked={doesPDR}
                                                            onChange={() => setDoesPDR(!doesPDR)}
                                                            type="checkbox"
                                                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <label htmlFor="PDR" className="font-medium text-gray-700">
                                                            PDR
                                                        </label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="detail"
                                                                name="detail"
                                                                checked={doesDetail}
                                                                onChange={() => setDoesDetail(!doesDetail)}
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                htmlFor="detail"
                                                                className="font-medium text-gray-700"
                                                            >
                                                                Detail
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="randi"
                                                                name="randi"
                                                                type="checkbox"
                                                                checked={doesRAndI}
                                                                onChange={() => setDoesRAndI(!doesRAndI)}
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                htmlFor="randi"
                                                                className="font-medium text-gray-700"
                                                            >
                                                                R&amp;I
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="overspray"
                                                                name="overspray"
                                                                checked={doesOverspray}
                                                                onChange={() => setDoesOverspray(!doesOverspray)}
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                htmlFor="overspray"
                                                                className="font-medium text-gray-700"
                                                            >
                                                                Overspray
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="mechanical"
                                                                name="mechanical"
                                                                checked={doesMechanical}
                                                                onChange={() => setDoesMechanical(!doesMechanical)}
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                htmlFor="mechanical"
                                                                className="font-medium text-gray-700"
                                                            >
                                                                Mechanical
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="paint"
                                                                name="paint"
                                                                type="checkbox"
                                                                checked={doesPaint}
                                                                onChange={() => setDoesPaint(!doesPaint)}
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                htmlFor="paint"
                                                                className="font-medium text-gray-700"
                                                            >
                                                                Paint
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div>
                                                    <div className="relative flex items-start">
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                id="diagnostics"
                                                                name="diagnostics"
                                                                checked={doesDiagnostics}
                                                                onChange={() => setDoesDiagnostics(!doesDiagnostics)}
                                                                type="checkbox"
                                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label
                                                                htmlFor="diagnostics"
                                                                className="font-medium text-gray-700"
                                                            >
                                                                Diagnostics
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

ProjectApplicationForm.propTypes = {
    project: PropTypes.shape({
        notes: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        numberOfVehicles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        client: PropTypes.string,
        clientName: PropTypes.string,
        id: PropTypes.string.isRequired,
        address: PropTypes.shape({
            city: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            zip: PropTypes.string.isRequired,
            street: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default ProjectApplicationForm;
