import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { IProject } from '../../Interfaces/IProject';
import LoaderComponent from '../../Shared/Loader';
import { getConfirmation, showError } from '../../utils/ui';
import ProjectApplicationForm from './ProjectApplicationForm';

const ProjectApplication = () => {
    // fetch project application with Project ID
    // show project data
    const { projectId } = useParams() as { projectId: string };
    const [project, setProject] = useState<IProject | null>(null);

    useEffect(() => {
        if (projectId) {
            db.collection(`/projects`)
                .doc(projectId)
                .get()
                .then((projectDoc) => {
                    if (!projectDoc.exists) {
                        throw new Error("This project doesn't exist");
                    }
                    setProject({
                        ...(projectDoc.data() as IProject),
                        id: projectDoc.id,
                    });
                })
                .catch(showError('There was an error finding this project'));
        }
    }, [projectId]);

    const onSubmit = useCallback(
        (data: any) => {
            db.collection(`/projects/${projectId}/applications`)
                .add(data)
                .then(async () => {
                    await getConfirmation(
                        'Thanks for submitting',
                        "We got your application. We'll reach out to you regarding the project ASAP. Please do not submit this form twice.",
                    );
                    window.location.reload();
                })
                .catch(showError('There was an error submitting the project application.'));
        },
        [projectId],
    );

    // db.doc(`/projects/${}`)

    return project ? <ProjectApplicationForm project={project} onSubmit={onSubmit} /> : <LoaderComponent />;
};

export default ProjectApplication;
